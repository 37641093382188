<template>
    <div class="full-width-overflow">
        <div class="about_block container-xl">
            <BlockTitle :title="contentPages?.title" class="about_block_content_title text-lg-start" />
            <div class="about_content_block_info">
                <div class="row">
                    <div class="about_content_info col-lg-6">
                        <template v-for="(aboutTextItem, aboutTextIndex) in contentPages?.content" :key="aboutTextIndex">
                            <div :class="`${aboutTextItem.type === 'title' ? 'about_content_with_title' : aboutTextItem.type === 'list' ? 'about_content_with_list' : 'about_content_text'}`">
                                <div v-if="aboutTextItem.type === 'title'" class="about_content_item_block">
                                    <p v-dompurify-html="aboutTextItem.text" class="about_content_item_content about_content_title" />
                                </div>
                                <div v-if="aboutTextItem.type === 'text'" class="about_content_item_block">
                                    <p v-dompurify-html="aboutTextItem.text" class="about_content_item_content" />
                                </div>
                                <div v-if="aboutTextItem.type === 'list'" class="about_content_item_block about_content_item_list">
                                    <div class="about_content_item_linear_block">
                                        <div class="about_content_item_linear"></div>
                                    </div>
                                    <span v-dompurify-html="aboutTextItem.content" class="about_content_item_text" />
                                </div>
                            </div>
                        </template>
                        <div class="mt-4">
                            <ConsultationBlock />
                        </div>
                    </div>
                    <div class="col-lg-6 d-none d-lg-block">
                        <img src="/images/city.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlockTitle from '~/pages/index/components/BlockTitle.vue';
import ConsultationBlock from '~/pages/index/components/ConsultationBlock.vue';

export default {
    name: 'InfoSection',
    components: { ConsultationBlock, BlockTitle },
    props: {
        contentPages: {
            type: Object,
        },
    },
};
</script>

<style>
.full-width-overflow {
    overflow: hidden;
}
.about_content_item_text a {
    color: #48538b;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
    text-decoration: none;
}
.about_content_block {
    padding: 80px 0 28px;
}

.about_block_content_title {
    font-size: 48px;
    line-height: 60px;
    width: 100%;
    font-weight: 600;
}

.about_content_item_content {
    margin: 0;
}

.about_content_with_title {
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: Calc(60px - 24px);
}

.about_content_with_title:first-child {
    margin-top: 0;
}

.about_content_with_title:nth-of-type(4) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 24px;
}

.about_content_with_list {
    margin-top: 16px;
}

.about_block_content_title_block {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.default_content_title_block {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.about_content_block_info {
    margin-top: 32px;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

.about_content_text:first-child {
    margin-top: 0;
}

.about_content_text {
    font-weight: 300;
}

.about_content_text:first-child .about_content_title {
    margin-top: 0;
    margin-bottom: 0;
}

.about_content_text,
.about_content_with_title {
    margin-top: 24px;
}

.about_content_with_title:first-child {
    margin-top: 0;
}

.about_content_info {
    max-width: 850px;
}

.about_block_content_advertisement {
    font-weight: bold;
    display: none;
    font-size: 24px;
    border-left: 4px solid black;
    padding: 12px;
}

.about_content_block_info_adress_list_block {
    margin-top: 35px;
}

.about_content_block_info_adress_list_item {
    margin-top: 16px;
    font-size: 16px;
    color: #2b2b2b;
}

.about_content_block_info_adress_linear {
    width: 20px;
    height: 1px;
    background: #aaaaaa;
}

.about_content_block_info_adress_text {
    margin-left: 8px;
}

.about_content_item_block {
    display: flex;
    align-items: flex-start;
}

.about_content_item_list {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

.about_content_item_linear_block {
    margin-right: 10px;
}

.about_content_item_linear {
    width: 20px;
    height: 1px;
    margin-top: 11px;
    background: #aaaaaa;
}

.about_block_title {
    position: relative;
}

.about_content_title_linear_block {
    display: none;
}

.about_content_title_linear {
    width: 4px;
    height: 100%;
    background: #ff0000;
}

@media (max-width: 1000px) {
    .about_block_content_title {
        font-size: 30px;
        margin-right: 10px;
    }

    .about_block_content_title_block {
        margin: 0 80px;
    }
}

@media (max-width: 768px) {
    .about_block_content_title_block {
        margin: 0 40px;
    }
}

@media (max-width: 480px) {
    .about_block_content_title_block {
        margin: 0;
    }
    .about_content_block_info {
        margin-top: 38px;
    }

    .about_block_content_advertisement {
        display: block;
    }

    .about_block_title {
        border-left: 4px solid #2b2b2b;
        padding: 0px 0px 0px 4px;
        width: 100%;
    }

    .about_content_title_linear_block {
        display: block;
    }

    .about_content_block {
        padding-bottom: 32px;
    }
}
</style>
